<template>
  <div style="margin-top: 7em">
    <Navbar jenis="biasa" />
    <div class="container pt-5">
      <div class="row">
        <div class="col-12 archive-header text-center pt-3 pb-3">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7BXX2cI7TyRkA5bigxxWq8BNDBmrUbQDlsA&usqp=CAU"
            alt=""
            class="mx-auto img-author shadow"
          />
          <h1 class="mb-3">
            {{ this.username }}
            <a
              active-class="active"
              class="btn rounded-pill btn-green text-green"
              v-if="
                this.$store.state.user.user.username != username &&
                this.$store.state.user.auth &&
                following
              "
              @click="followUnfollow('un')"
              >Unfollow</a
            >
            <a
              active-class="active"
              class="btn rounded-pill btn-green text-green"
              v-if="
                this.$store.state.user.user.username != username &&
                this.$store.state.user.auth &&
                !following
              "
              @click="followUnfollow('fol')"
              >Follow</a
            >
          </h1>
          <p class="archive-intro">{{ bio }}</p>
        </div>
      </div>

      <div class="divider"></div>

        <h4 class="text-muted mb-2 text-center" v-show="mostLiked == ''">No Post Here, iam so sad :(</h4>

      <div class="row justify-content-between post-has-bg ml-0 mr-0" v-if="mostLiked != ''">
        <div
          class="col-lg-6 col-md-4 bgcover d-none d-md-block pl-md-0 ml-0"
          style="
            background-image: url('http://demos.alithemes.com/html/merinda/assets/images/thumb/thumb-1400x778.jpg');
          "
        ></div>
        <div class="col-lg-6 col-md-8">
          <div class="pt-5 pb-5 pl-md-5 pr-5 align-self-center">
            <div class="capsSubtle mb-2">Most Liked</div>
            <h2 class="entry-title mb-3">
              <a :href="`/${mostLiked.url_perma}`">{{ mostLiked.title }}</a>
            </h2>
            <div class="entry-excerpt">
              <p>
                {{ mostLiked.description }}
              </p>
            </div>
            <div class="entry-meta align-items-center">
              <a :href="`/@${this.username}/me`">{{ this.username }}</a> in
              <a href="">Random</a><br />
              <span>July 15</span>
              <span class="middotDivider"></span>
              <span class="readingTime" title="3 min read">5 min read</span>
              <span class="svgIcon svgIcon--star">
                <svg class="svgIcon-use" width="15" height="15">
                  <path
                    d="M7.438 2.324c.034-.099.09-.099.123 0l1.2 3.53a.29.29 0 0 0 .26.19h3.884c.11 0 .127.049.038.111L9.8 8.327a.271.271 0 0 0-.099.291l1.2 3.53c.034.1-.011.131-.098.069l-3.142-2.18a.303.303 0 0 0-.32 0l-3.145 2.182c-.087.06-.132.03-.099-.068l1.2-3.53a.271.271 0 0 0-.098-.292L2.056 6.146c-.087-.06-.071-.112.038-.112h3.884a.29.29 0 0 0 .26-.19l1.2-3.52z"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div class="row">
        <article
          v-show="mostLiked != ''"
          class="post-list-style-2 mb-5 col-md-4"
          v-for="item in data"
          :key="item.index"
        >
          <a href="single.html"
            ><figure
              class="bgcover"
              :style="`background-image:url('${item.thumbail_url}');`"
            ></figure
          ></a>
          <h3 class="entry-title mb-3">
            <a href="single.html">{{ item.title }}</a>
          </h3>
          <div class="entry-excerpt">
            <p>
              {{ item.description }}
            </p>
          </div>
          <div class="entry-meta align-items-center">
            <a :href="`/${item.url}`">{{ username }}</a> in <a href="">Random</a
            ><br />
            <span>{{ item.updated_date }}</span>
            <span class="middotDivider"></span>
            <span class="readingTime" title="3 min read">{{ item.reading.text }}</span>
            <span class="svgIcon svgIcon--star">
              <svg class="svgIcon-use" width="15" height="15">
                <path
                  d="M7.438 2.324c.034-.099.09-.099.123 0l1.2 3.53a.29.29 0 0 0 .26.19h3.884c.11 0 .127.049.038.111L9.8 8.327a.271.271 0 0 0-.099.291l1.2 3.53c.034.1-.011.131-.098.069l-3.142-2.18a.303.303 0 0 0-.32 0l-3.145 2.182c-.087.06-.132.03-.099-.068l1.2-3.53a.271.271 0 0 0-.098-.292L2.056 6.146c-.087-.06-.071-.112.038-.112h3.884a.29.29 0 0 0 .26-.19l1.2-3.52z"
                ></path>
              </svg>
            </span>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/layouts/Navbar";
import axios from "axios";
import moment from 'moment'
import $ from "jquery";
import readingTime from 'reading-time'

export default {
  data() {
    return {
      mostLiked: "",
      data: "",
      username: "",
      bio: "",
      id: "",
      following: false,
    };
  },
  components: {
    Navbar,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const data = await axios.get(`auth/@${this.$route.params.username}`);
      this.id = data.data.data.id;
      this.username = data.data.data.username;
      this.bio = data.data.data.bio;
      const checking = data.data.data.followers.filter(el => el.id_follow == this.$store.state.user.user.id)
      if (checking.length > 0) this.following = true; 
      document.title = `@${this.$route.params.username} - Random`;
      const ini = data.data.data.posts;
      if (ini.length > 0) {
        const mostLike = ini.splice(0, 1);
        this.mostLiked = mostLike[0];
        this.mostLiked.description = $(this.mostLiked.description)
          .text()
          .trim()
          .substr(0, 500);
        let arrayData = [];
        ini.forEach(async (element) => {
          let toPush = "";
          const startDesc = element.description.indexOf(
            '<p class="blog_post_text text-left">'
          );
          if (startDesc < 0) {
            toPush = "Nothing paragraph in here";
          } else {
            const jadi_string = $(element.description)
              .text()
              .trim()
              .substr(0, 100);
            toPush = jadi_string;
          }
          const time = readingTime($(element.description).text().trim())
          const data = {
            id: element.id,
            title: element.title,
            description: toPush,
            thumbail_url: element.thumbail_url,
            url: element.url_perma,
            updated: element.date_updated != "non" ? true : false,
            updated_date: (element.date_updated != "non") ? moment(element.date_updated).format('MMMM D') : moment(element.date_created).format('MMMM D'),
            reading: time
          };
          arrayData.push(data);
        });
        this.data = arrayData;
      }
    },
    followUnfollow(data) {
      switch (data) {
        case "un":
          this.following = false;
          axios.post("follow/destroy", { who_follow: this.id });
          break;
        case "fol":
          this.following = true;
          axios.post("follow", { who_follow: this.id });
          break;

        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.img-author {
  border-radius: 50%;
  margin-bottom: 25px;
}
.archive-intro {
  max-width: 500px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.54) !important;
}

.divider {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 1.5rem;
  margin-bottom: 2.25rem;
}
.post-has-bg {
  background: #fafafa;
  margin-bottom: 2rem;
}
.post-list-style-2 figure {
  height: 300px;
}
</style>